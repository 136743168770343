.font-1 {
    font-size: 24px;
}

.font-2 {
    font-size: 20px;
}

.font-3 {
    font-size: 18px;
}

.font-4 {
    font-size: 16px;
}

.black-1{
    font-size: var(--font-6);
    font-weight: var(--black);
}

.bold{
    font-weight: var(--bold) !important;
}