:root {
    --color-background: #beeb9f;
    --color-bug: #00a388;
    --color-text: #00a388;
}

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    background: var(--color-background);
}

.error-code {
    display: flex;
    grid-gap: 4vw;
    position: relative;
    border: 0;
    background: none;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    letter-spacing: 0.1em;
    white-space: nowrap;
    padding: 4vw;
    border-radius: 1vw;
    transition: all 250ms ease;
}
.to-home{
    background-color: #282c34;
    position: relative;
}
.error-code:after {
    content: "Parece que encontraste un error.";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
    color: var(--color-text);
    opacity: 0;
    transition: all 500ms ease;
    transition-delay: 1000ms;
}

.error-code:hover:after {
    opacity: 1;
}
.error-code:hover .cell.filled:nth-child(1) {
    transform: rotate(15deg);
}
.error-code:hover .cell.filled:nth-child(2) {
    transform: rotate(30deg);
}
.error-code:hover .cell.filled:nth-child(3) {
    transform: rotate(45deg);
}
.error-code:hover .cell.filled:nth-child(4) {
    transform: rotate(60deg);
}
.error-code:hover .cell.filled:nth-child(5) {
    transform: rotate(75deg);
}
.error-code:hover .cell.filled:nth-child(6) {
    transform: rotate(90deg);
}
.error-code:hover .cell.filled:nth-child(7) {
    transform: rotate(105deg);
}
.error-code:hover .cell.filled:nth-child(8) {
    transform: rotate(120deg);
}
.error-code:hover .cell.filled:nth-child(9) {
    transform: rotate(135deg);
}
.error-code:hover .cell.filled:nth-child(10) {
    transform: rotate(150deg);
}
.error-code:hover .cell.filled:nth-child(11) {
    transform: rotate(165deg);
}
.error-code:hover .cell.filled:nth-child(12) {
    transform: rotate(180deg);
}
.error-code:hover .cell.filled:nth-child(13) {
    transform: rotate(195deg);
}
.error-code:hover .cell.filled:nth-child(14) {
    transform: rotate(210deg);
}
.error-code:hover .cell.filled:nth-child(15) {
    transform: rotate(225deg);
}
.error-code:hover .cell.filled:nth-child(16) {
    transform: rotate(240deg);
}
.error-code:hover .cell.filled:nth-child(17) {
    transform: rotate(255deg);
}
.error-code:hover .cell.filled:nth-child(18) {
    transform: rotate(270deg);
}
.error-code:hover .cell.filled:nth-child(19) {
    transform: rotate(285deg);
}
.error-code:hover .cell.filled:nth-child(20) {
    transform: rotate(300deg);
}
.error-code:hover .cell.filled:nth-child(21) {
    transform: rotate(315deg);
}
.error-code:hover .cell.filled:nth-child(22) {
    transform: rotate(330deg);
}
.error-code:hover .cell.filled:nth-child(23) {
    transform: rotate(345deg);
}
.error-code:hover .cell.filled:nth-child(24) {
    transform: rotate(360deg);
}
.error-code:hover .number {
    grid-gap: 2vw;
}
.error-code:hover .bug {
    transform: translateY(-200vh);
    transition-delay: 500ms;
}
.error-code:hover .bug:before {
    top: -0.5vw;
}
.error-code:hover .bug:after {
    left: -0.75vw;
    right: -0.75vw;
    height: 1.5vw;
}

.number {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    transition: all 500ms ease;
    grid-gap: 0.25vw;
    margin: auto 2vw;
}

.cell.filled {
    transition: all 500ms ease;
}

.bug {
    background: var(--color-bug);
    transition: transform 4000ms ease;
    width: 2.25vw;
    height: 2.5vw;
    position: relative;
    border-radius: 100%;
    transition-delay: 0;
}
.bug:before {
    content: "";
    height: 1.5vw;
    width: 1.5vw;
    display: block;
    position: absolute;
    top: 0;
    left: 0.375vw;
    background: var(--color-bug);
    border-radius: 100%;
    z-index: -1;
    transition: all 1000ms ease;
}
.bug:after {
    content: "";
    display: block;
    position: absolute;
    left: 0.5vw;
    right: 0.5vw;
    bottom: 0.5vw;
    height: 0;
    transition: all 250ms ease;
    animation: infinite skitter 100ms ease;
    background: top left/100% 100% linear-gradient(transparent, transparent 45%, var(--color-bug) 45%, var(--color-bug) 55%, transparent 55%), top left/100% 100% linear-gradient(to bottom right, transparent, transparent 45%, var(--color-bug) 45%, var(--color-bug) 55%, transparent 55%), top left/100% 100% linear-gradient(to bottom left, transparent, transparent 45%, var(--color-bug) 45%, var(--color-bug) 55%, transparent 55%);
}

@keyframes skitter {
    0%, 100% {
        transform: rotate(-35deg);
    }
    50% {
        transform: rotate(35deg);
    }
}