:root {
    --color-1: #014166;
    --color-2: #03A2FF;
    --color-3: #e7eaed;
    --color-4: #285e80;
    --color-5: #01314d;
    --color-6: #fde029;
    --color-7: gray;
    --color-8: #bcd3e1;
    --color-9: #1399d6;
    --color-11: #014973;
    --color-10: #0272b3;
    --color-12: #f5f5f5;
    --color-13: #dadada;
    --color-14: #F6F6F6;
    --color-15: #FA213F;
    --color-16: #03A1FF;


    --news-title-bg: url('../../assets/img/news-title-bar.png');
    --senators-title-bg: url('../../assets/img/senator-bar.png');
    --home-video-title-bg: url('../../assets/img/barra-para-videos.png');
    /*--legislative-title-bg: url('src/assets/img/bar3.png');*/
    --download-icon: url('../../assets/img/descarga-PDF-blanco.png');
    --download-icon-hover: url('../../assets/img/descarga-PDF-color.png');
    --bar-1: url('../../assets/img/bars/bar1.png');
    --bar-2: url('../../assets/img/bars/bar2.png');
    --bar-3: url('../../assets/img/bars/bar3.png');
    --bar-4: url('../../assets/img/bars/bar4.png');
    --line-1: url('../../assets/img/footer/Linea-footer.png');
    --height-title-section: 60px;
    --bg-title-section: var(--color-1);
    /* TEXTS */
    --font: 'Roboto', serif;
    --font-5: 18px;
    --font-6: 20px;
    --font-7: 18px;
    --font-8: 16px;
    --line-height-1: 2.5rem;
    --medium: 500;
    --regular: 300;
    --bold: 700;
    --black: 900;
}
