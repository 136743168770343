.contact {
    padding: 3rem;
    position: relative;
    background-color: var(--color-1);
    border-radius: 3rem;
    border: solid 5px var(--color-2);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .9;
}

.contact h3 {
    color: #fff
}

.contact-form textarea.form-control {
    height: inherit !important;
    min-height: 250px !important;
}

.contact-form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-1) !important;
    opacity: 1; /* Firefox */
}

.contact-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-1) !important;
}

.contact-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-1) !important;
}

.contact-form .form-control:focus, .contact-form .form-control {
    color: var(--color-1);
}

.form-title, .form-subtitle {
    color: var(--color-3) !important;
    font-weight: var(--bold);
}

.form-title {
    text-align: center;
}

.icon-100p {
    height: 100px;
}
.icon-label{
    color: var(--color-3);
    margin-top: 0.5rem;
    font-size: var(--font-6);
}
