.publication .modal-content {
    border-radius: 0 !important;
}

.publication .modal-header {
    border-bottom: none !important;
}

.publication .modal-footer {
    border-top: none !important;
}

.publication-img {
    min-height: 60vh;
    max-height: 60vh;
}
