.circle-left, .circle-right, .circle-top {
    width: 250px !important;
    height: 250px !important;
}

.circle-left {
    left: -170px;
}

.circle-right {
    right: -170px;
}

.circle-top{
    top: -170px;
}

.text-left {
    margin-left: 80px;
}

.text-right {
    margin-right: 80px;
}

.text-top{
    margin-top: 80px;
}

.margin-top{
    margin-top: 150px;
}

@media (min-width: 375px) {

    .circle-left, .circle-right, .circle-top {
        width: 100px !important;
        height: 100px !important;
    }
    .circle-left {
        left: -100px;
    }

    .circle-right {
        right: -100px;
    }

    .circle-top{
        top: -100px;
    }

    .text-left {
        margin-left: 0;
    }

    .text-right {
        margin-right: 0;
    }

    .text-top{
        margin-top: 0;
    }
    .margin-top{
        margin-top: 150px;
    }
}


@media (min-width: 792px) {

    .circle-left, .circle-right, .circle-top {
        width: 200px !important;
        height: 200px !important;
    }
    .circle-left {
        left: -150px;
    }

    .circle-right {
        right: -150px;
    }

    .circle-top{
        top: -150px;
    }

    .text-left {
        margin-left: 50px;
    }

    .text-right {
        margin-right: 50px;
    }

    .text-top{
        margin-top: 50px;
    }
    .margin-top{
        margin-top: 150px;
    }
}


@media (min-width: 900px) {

    .circle-left, .circle-right, .circle-top {
        width: 200px !important;
        height: 200px !important;
    }
    .circle-left {
        left: -150px;
    }

    .circle-right {
        right: -150px;
    }

    .circle-top{
        top: -150px;
    }

    .text-left {
        margin-left: 50px;
    }

    .text-right {
        margin-right: 50px;
    }

    .text-top{
        margin-top: 50px;
    }
    .margin-top{
        margin-top: 150px;
    }
}
