tr.table-1-header {
    background: var(--color-1);
    color: var(--color-3);
}

.table-1 tbody tr:nth-child(odd) {
    background: white;
}

.table-1 tbody tr:nth-child(even) {
    background: var(--color-8);
}

.table-1 tr td:nth-child(1), .table-1 tr th:nth-child(1) {
    text-align: center;
}

.table-1 th, .table-1 td {
    vertical-align: middle;
}

table.table-1 {
    border-collapse: collapse;
    border-spacing: 10px 5px; /* Apply cell spacing */
}