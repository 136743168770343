table.regulatory {
    border-collapse: separate;
    border-spacing: 10px 5px; /* Apply cell spacing */
}

.regulatory tr, .regulatory th, .regulatory td {
    border: none;
}

.regulatory-download {
    max-width: 150px !important;
    width: 150px;
}

.regulatory-title {
    color: var(--color-1);
    font-size: var(--font-5);
    font-weight: var(--black);
    padding: 1rem 0;
}

.regulatory-header {
    background: var(--color-1);
    color: white;
}

.regulatory tbody tr td {
    border: solid var(--color-3) 2px;
}

.regulatory-button {
    padding: 0 !important;
    margin: 0 !important;
}

.regulatory-data {
    vertical-align: middle;
}