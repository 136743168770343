@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
    --cbs-bg-opacity: 1;
    --cbs-primary-rgb: 42, 42, 42;
    --Ad-rgb: 2, 154, 242;
    --Bd-rgb: 2, 114, 179;
    --Cd-rgb: 1, 65, 102;
    --Dd-rgb: 1, 73, 115;
    --Ed-rgb: 1, 49, 77;
    --Al-rgb: 2, 114, 179;
    --Bl-rgb: 40, 95, 128;
    --Cl-rgb: 1, 65, 102;
    --Dl-rgb: 55, 133, 179;
    --El-rgb: 3, 162, 255;
    --final-color: 0, 38, 72;
    --nav-pill-color: #b89b5e;
    --height-law-buttons: 200px;
}

*, html {
    font-family: 'Roboto', serif;
}

a, a:hover, a:visited, a:link {
    text-decoration: none;
}

.container-fluid {
    overflow-x: hidden;
}

.border-r {
    border-right: #fff solid 1px;
}

.width-30 {
    width: 30px;
}

.height-30 {
    height: 30px !important;
}

.bg-e {
    background: #01314D;
}


img {
    vertical-align: middle;
    border-style: none
}

.btn.btn-white {
    background: #fff;
    border: 1px solid #fff;
    color: #000;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0
}

.btn.btn-white:hover {
    border: 1px solid #000;
    background: #000;
    color: #fff
}

.btn.btn-white.btn-outline-white {
    border-color: rgba(255, 255, 255, .8);
    background: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border-width: 1px;
    color: #fff
}

.btn.btn-white.btn-outline-white:hover, .btn.btn-white.btn-outline-white:focus, .btn.btn-white.btn-outline-white:active {
    background: #fff;
    border-color: #fff;
    color: #f86f2d
}

.fs-16 {
    font-size: 16px;
}

* {
    font-family: 'roboto';
}

a {
    text-decoration: none;
}

.border-radius-12 {
    border-radius: 12px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.border-radius-25 {
    border-radius: 25px;
}

.border-radius-0-5 {
    border-radius: 0.5rem;
}


.h-50p {
    height: 50px !important;
}

@media (min-width: 992px) {
    .h-50p {
        height: 25px;
    }
}

@media (min-width: 768px) {
    .h-50p {
        height: 15px;
    }
}

@media (min-width: 576px) {
    .h-50p {
        height: 10px;
    }
}

.h-100vh {
    height: 100vh;
}

.h-50vh {
    height: 50vh;
}

button.bg-5:hover {
    background: var(--color-2);
    color: white;
}

ul {
    list-style-type: none;
}


.bg-title-section {
    height: var(--height-title-section) !important;
    background: var(--bg-title-section) no-repeat center/contain var(--img);
    width: 100%;
    background-size: 100% 100%;
}

.title-section {
    color: white;
    text-transform: uppercase;
    padding: 0 15px;
    z-index: 2;
    margin: 0;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--black);
    text-align: center;
}

.title-section-2 {
    color: white;
    text-transform: uppercase;
    height: 36px;
    font-weight: var(--black);
}

.download-pdf {
    background: var(--color-2);
    color: var(--color-3);
    border-radius: 0;
    padding: 10px 5px;
}

.download-pdf div.icon {
    width: 30px;
    background: no-repeat center/contain var(--download-icon);
    height: 100% !important;
}

.download-pdf:hover, .download-pdf:focus {
    color: var(--color-2);
    background: var(--color-3);
}

.download-pdf:hover div.icon, .download-pdf:focus div.icon {
    background: no-repeat center/contain var(--download-icon-hover);
}

.pagination {
    display: flex;
}

.previous-next {
    padding: 5px 10px;
}

.page-item {
    background: var(--color-8);
    border-left: solid var(--color-8) 1px;
    border-bottom: solid var(--color-8) 1px;
    border-right: solid black 1px;
    margin: 0 1px;
    color: var(--color-2);
    display: flex;
    align-items: center;
}

.page-item a {
    width: 100% !important;
    height: 100% !important;
    padding: 5px 10px;
}

.page-item:hover, .page-selected {
    color: var(--color-8);
    background: var(--color-2);
}

.previous-next {
    background: var(--color-2);
    color: var(--color-3);
    text-transform: uppercase;
}

.previous-next:hover {
    color: var(--color-2) !important;
    background: var(--color-3);
}

.disable-button, .disable-button:hover {
    background: none;
}

.disable-button a, .disable-button:hover a {
    cursor: none !important;
    color: var(--color-3);
}

.p-1p {
    padding: 1px !important;
}

.w-60 {
    width: 60% !important;
}

.title-1 {
    color: var(--color-1);
    font-weight: var(--bold);
    font-size: var(--font-5);
    text-transform: uppercase;
}
.title-1-n {
    color: var(--color-1);
    font-weight: var(--bold);
    font-size: var(--font-5) !important;
}
.description-title-1{
    font-weight: var(--bold) !important;
    color: var(--color-1) !important;
}

.title-2 {
    color: var(--color-2);
    font-weight: var(--black);
    font-size: var(--font-6);
    text-transform: uppercase;
}

.title-description-1 {
    color: var(--color-1);
    font-weight: var(--bold);
    font-size: var(--font-7);
    text-transform: capitalize;
}

.title-description-2 {
    color: var(--color-1);
    font-weight: var(--bold);
    font-size: var(--font-7);
}

.description-1, div.description-1 * {
    font-size: var(--font-7) !important;
    line-height: var(--line-height-1) !important;
    color: var(--color-1) !important;
    font-weight: var(--regular) !important;
    text-align: justify !important;
}
.description-1-n{
    font-size: var(--font-5) !important;
    line-height: var(--line-height-1) !important;
    color: var(--color-1) !important;
    font-weight: var(--regular) !important;
    text-align: justify !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

ol.list-1 {
    counter-reset: list;
}

ol.list-1 > li {
    list-style: none;
    position: relative;
}

ol.list-1 > li:before {
    counter-increment: list;
    content: counter(list, lower-alpha) ") ";
    position: absolute;
    left: -1.4em;
    font-weight: var(--bold);
}

.border-3 {
    border: var(--color-3) solid 2px
}

.skeleton-text {
    height: 30px !important;
}

.single-line-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.root-link:hover {
    color: var(--color-9) !important;
    cursor: pointer;
}
.btn-danger{
    --bs-btn-bg: var(--color-15) !important;
}
