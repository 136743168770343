.footer-bg {
    background: url('../assets/img/footer/Detalles-andinos.png') no-repeat var(--color-1);
}

.footer-line {
    background: url("../assets/img/footer/Linea-footer.png") no-repeat;
    height: 5px;
}

.footer-bottom {
    background: var(--color-1);
    height: 80px;
}

.footer {
    padding: 0 !important;
    margin: 0 !important;
}

.link-footer {
    word-break: break-word;
}

.footer-media.bg-10:hover {
    color: var(--color-2);
    background: var(--color-3);
}