body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-item a:hover {
  border-bottom: 3px solid #08446a;
}

.nav-item a.active {
  border-bottom: 3px solid #08446a;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

a {
  color: #e8ecf1 ;
}

.card-leyes {
  background-color: white;
  top: -80px;
}
.font-nav-main {
  font-size: 18px;
  font-weight: bold;
  color: #002c5d;
}
.nav-main {
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.z-index-container {
  z-index: 100
}
/*custom*/
.nav-link {
  color: #f5f6f8;
}
.bg-body {
   --bs-bg-opacity: 0;
   /*background-color: rgba(var(--bs-body-bg-rgb),var(--bs-bg-opacity))!important; */
}

.border-bottom-custom {
  padding-top: 10px;
  border-bottom: 1px solid #f3ecec !important;
}
.border-nav-custom {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}



.nav-link-submenu {
  color: #6f8eca;
}
.nav-pills .nav-link .nav-item >a:hover {
color: #61dafb;
}
.nav-pills .nav-link.active{
  background-color: unset;
}

.btn-light {
  background-color: #caced3;
}
