.search, .search:hover, .search:focus {
    border: none !important;
    outline: none;
    width: 98%;
    background: var(--color-3);
}

.search-icon {
    height: 100%;
    position: absolute;
    left: 5px;
}
.search-container {
    border-bottom: solid black 1px;
    border-right: solid black 1px;
    border-top: solid var(--color-3) 1px;
    border-left: none;
    width: 100%;
    box-shadow: 3px 0 3px 0 var(--color-7);
    background: var(--color-3);
}
.search-container input{
    font-size: var(--font-7) !important;
}
.date-container{
    background: var(--color-3);
    min-width: 200px;
    min-height: 36px;
    border-bottom: solid black 1px;
    border-right: solid black 1px;
    border-top: solid var(--color-3) 1px;
    border-left: none;
    box-shadow: 3px 0 3px 0 var(--color-7);
}
.date-picker-container{
    position: absolute;
    top: 50px;
    z-index: 99;
}
.react-datetime-picker.react-datetime-picker{
    height: 100%;
    width: 100%;
}

.search-button{
    height: 100% !important;
    border-radius: 0;
    background: var(--color-2);
    color: var(--color-3);
    padding: 10px 25px;
}
.search-button:hover{
    color: var(--color-2);
    background: var(--color-3);
}

.label-search{
    min-width: 100px;
}