:root {
    --wraper-padding: 20px;
}

.custom-navbar {
    background: var(--color-10);
    /*position: fixed;*/
    width: 100%;
    z-index: 999;
    height: 65px !important;
    line-height: 65px !important;
}

.drop-menu li a::after, .mega-links li a::after {
    content: "";
    position: absolute;
    background-color: var(--color-3);
    height: 3px;
    width: 0;
    left: 0;
    bottom: 0;
    transition: 0.3s;
}

.drop-menu li a:hover::after, .mega-links li a:hover::after {
    width: 100%;
}


.custom-navbar .wrapper {
    width: 100%;
    padding: 0 var(--wraper-padding);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapper .logo a {
    color: #f2f2f2;
    text-decoration: none;
    font-size: 27px;
    font-weight: 600;
}

.wrapper .nav-links {
    display: flex;
    justify-content: space-between;
}

.nav-links li {
    list-style: none;
}

.nav-links li a, .nav-links li div span.link-title {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.nav-links li a:hover, .nav-links li div span.link-title:hover {
    background: var(--color-1);
}

.nav-links .drop-menu {
    background: var(--color-10);
    width: auto;
    top: 85px;
    line-height: 45px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    padding: 5px 15px;
}

.nav-links li:hover .drop-menu, .nav-links li:hover .mega-box {
    top: 70px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}
.nav-links li:hover .arrow-up{
    visibility: visible;
    transition: all 0.3s ease;
}
.arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--color-10);
    top: -12px;
    left: 15px;
    position: relative;
    visibility: hidden;
}
.drop-menu li a {
    width: 100%;
    display: block;
    padding: 0 15px;
    font-weight: 400;
    border-radius: 0;
}

.custom-navbar .wrapper .drop-menu li, .custom-navbar .wrapper .mega-box .mega-links li,
.custom-navbar .wrapper .mega-box header {
    position: relative;
}

.mega-box {
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 30px;
}

.mega-box .content-mega-box {
    background: var(--color-10);
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-radius: 0.5rem;
}

.content-mega-box .row-custom-navbar {
    width: calc(var(--menuItems)% - var(--wraper-padding));
    line-height: 45px;
}

.content-mega-box .row-custom-navbar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-mega-box .row-custom-navbar header {
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
}

.content-mega-box .row-custom-navbar:nth-child(1),
.content-mega-box .row-custom-navbar:nth-child(2) {
    border-left: 0;
}

.content-mega-box .row-custom-navbar .mega-links {
    margin-left: -45px;
    border-left: 1px solid rgba(255, 255, 255, 0.08);
    height: 100% !important;
    margin-bottom: 10px;
}

.row-custom-navbar .mega-links li {
    padding: 0;
    margin-left: 20px;
}

.row-custom-navbar .mega-links li a {
    padding: 0 20px;
    color: #d9d9d9;
    font-size: 17px;
    display: block;
}

.row-custom-navbar .mega-links li a:hover {
    color: #f2f2f2;
    cursor: pointer;
}

.wrapper .btn {
    color: #fff;
    display: none;
}

.wrapper .btn.cancel-btn {
    position: absolute;
    right: 30px;
    top: 10px;
}

.wrapper input {
    display: none;
}

@media screen and (max-width: 970px) {
    /*.custom-navbar {*/
    /*    width: 100vw !important;*/
    /*    overflow-x: hidden;*/
    /*}*/
    .wrapper .btn {
        display: block;
    }

    .wrapper .nav-links {
        position: fixed;
        height: 100vh;
        width: 100vw;
        /* max-width: 350px; */
        background: var(--color-1);
        display: block;
        top: 0;
        left: -100%;
        overflow-y: auto;
        line-height: 50px;
        padding: 50px 10px;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease;
    }

    #menu-btn:checked ~ .nav-links {
        left: 0;
    }

    .nav-links::-webkit-scrollbar {
        width: 0;
    }

    .nav-links li {
        margin: 15px 10px;
    }

    .nav-links li a {
        padding: 0 20px;
        display: block;
        font-size: 20px;
    }

    .nav-links .drop-menu {
        position: static;
        opacity: 1;
        visibility: visible;
        top: 65px;
        padding-left: 20px;
        width: 100%;
        background: var(--color-1);
    }

    .nav-links .drop-menu li {
        margin: 0;
    }

    .nav-links .drop-menu li a {
        font-size: 18px;
        border-radius: 5px;
    }

    .mega-box {
        position: static;
        top: 65px;
        width: 100%;
        left: 0;
        opacity: 1;
        visibility: visible;
        padding: 0 30px;
    }

    .mega-box .content-mega-box {
        background: var(--color-1);
        padding: 20px 20px 0 20px;
        display: flex;
        flex-direction: column;
    }

    .mega-box .content-mega-box .row-custom-navbar {
        width: 100%;
        margin-bottom: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
    }

    .mega-box .content-mega-box .row-custom-navbar:nth-child(1),
    .mega-box .content-mega-box .row-custom-navbar:nth-child(2) {
        border-top: none;
    }

    .content-mega-box .row-custom-navbar .mega-links {
        border-left: none;
        padding-left: 15px;
    }

    .row-custom-navbar .mega-links li {
        margin: 0;
    }

    .content-mega-box .row-custom-navbar header {
        font-size: 19px;
    }
    .nav-links li:hover .arrow-up{
        visibility: hidden;
    }
}