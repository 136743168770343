.img-carrousel {
    height: auto;
    width: 100% !important;
}

@media (max-width: 576px) {
    .img-carrousel {
        height: auto !important;
    }
}
