
.cbg-primary {
    opacity: var(--cbs-bg-opacity);
    background-color: rgba(var(--final-color), var(--cbs-bg-opacity)) !important;
}

.nav-pill-color li a {
    color: var(--nav-pill-color) !important;
}

.bg-1 {
    background: var(--color-1);
}

.bg-2 {
    background: var(--color-10);
}

.bg-3 {
    background: var(--color-3);
}

.bg-4 {
    background: var(--color-4);
}

.bg-5 {
    background: var(--color-5);
}
.bg-6 {
    background: var(--color-6);
}

.bg-9 {
    background: var(--color-9);
}

.bg-10 {
    background: var(--color-2);
}

.bg-12 {
    background: var(--color-12);
}

.bg-13 {
    background: var(--color-13);
}
.bg-16{
    background: var(--color-16);
}

.fg-white {
    color: white;
}

.fg-1 {
    color: var(--color-1);
}

.fg-2 {
    color: var(--color-10);
}

.fg-3 {
    color: var(--color-3);
}

.fg-6 {
    color: var(--color-6) !important;
}

.fg-8 {
    color: var(--color-8) !important;
}

.fg-9 {
    color: var(--color-9) !important;
}
.fg-10 {
    color: var(--color-2);
}