
#sticky-header .nav-link, #sticky-header .dropdown-item {
    color: #fff !important;
    font-weight: 900 !important;
}

#sticky-header .fg-6{
    color: var(--color-6) !important;
    font-weight: 900 !important;
}

#sticky-header .dropdown-menu {
    background-color: rgb(0, 38, 72) !important;
}
.header-img-container{
    max-height: 200px !important;
}
.toggle-header{
    background: white;
    color: white;
}

.sticky-1{
    top: -1px !important;
}

.navbar-img{
    height: 100% !important;
    padding: 2px;
}
