
.card-1, .card-1-skeleton {
    background: var(--color-14);
    transition: background-color 0.5s ease-in;
    border-radius: 0 !important;
    /*border: none;*/
    /*border-right: black solid 1px;*/
    /*border-bottom: black solid 1px;*/
    /*box-shadow: 1px 1px 1px 1px var(--color-7);*/
}

.card-1:hover {
    background: var(--color-10);
    transition: background-color 0.5s ease-in-out;
}


.border-img {
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(
            to top,
            var(--color-10),
            rgba(0, 0, 0, 0)
    ) 1 100%;
}

.border-img-bottom {
    border-image-width: 0 0 0 auto;
    border-bottom-color: var(--color-10);
}

.card-1-img, .card-1-img-skeleton {
    height: 200px !important;
    object-fit: contain;
    object-position: center;
}

.fullName {
    text-transform: uppercase;
    font-size: var(--font-8);
    color: var(--color-1);
    font-weight: var(--medium);
}

.bench {
    text-transform: uppercase;
    font-size: var(--font-8);
    color: var(--color-2);
    font-weight: var(--bold);
}

.city {
    text-transform: uppercase;
    font-size: var(--font-7);
    color: var(--color-1);
    font-weight: var(--black) !important;
}

.card-1:hover .fullName, .card-1:hover .city {
    color: var(--color-14) !important;
}

.card-1:hover .bench {
    color: var(--color-3) !important;
}

.card-1:hover .border-img-bottom {
    border-image-width: 0 0 0 auto !important;
    border-bottom-color: var(--color-1) !important;
}

.card-1:hover .border-img {
    border-image: linear-gradient(
            to top,
            var(--color-1),
            rgba(0, 0, 0, 0)
    ) 1 100%;
}
